import { Events, Places } from './event-model';

export const LS_USER_KEY = 'alyx-user-key';
export const LS_USER_ID = 'alyx-user-id';

/**
 * Return the right units for each kind of event data field.
 *
 * @param {string} fieldName - The name of the field as a custom field.
 * @returns {string} The unit string to use. 
 */
export const getFieldUnits = (fieldName) => {
  const map = {
    dimensions: 'M x mm',
    quantity: 'units',
    shippingWeight: 'Kg',
  };

  return map[fieldName] || '';
};

/**
 * Map place resources to the { label, value } format expected by the shared component.
 *
 * @param {Array} places - The places to map.
 * @returns {Array} List of transformed places.
 */
export const convertPlacesForSelect = places =>
  [{ label: 'Select a location...', value: '' }]
    .concat(places.map(p => ({ label: p.name, value: p.id })));

/**
 * Show only some event types for each location.
 * Also include the empty string one so that a choice is forced (updates state)
 *
 * @param {object} state - The app state.
 * @returns {Array} Array of filtered event types for this location.
 */
export const filterEventTypes = state => {
  // Return  action types for the currently selected state.place (id)
  const found = Object.keys(Places).find(key => Places[key].id === state.place);
  if (!found) {
    return [];
  }

  const initial = [{ label: 'Select event type...', value: '' }];
  return initial.concat(Places[found].events.map(p => ({
    label: Events[p].friendlyName,
    value: p,
  })));
};

/**
 * Asynchronous wait function that works with Promises.
 *
 * @param {number} ms - Number of milliseconds to wait.
 */
const waitAsync = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Run a batch of tasks (functions that return async functions) in a queue.
 *
 * @param {Array} queue - Queue of tasks.
 * @param {number} numInParallel - Number to do in a group.
 * @param {number} waitMs - Time to wait between groups.
 */
export const runTaskBatch = (queue, numInParallel = 1, waitMs = 100) => {
  if (!queue || !queue.length) {
    return Promise.resolve();
  }

  const batch = queue.splice(0, numInParallel);
  return Promise.all(batch.map(item => item()))
    .then(() => waitAsync(waitMs))
    .then(() => runTaskBatch(queue, numInParallel, waitMs));
};
