import React from 'react';
import { Colors, Fonts } from '../../../consumer-web-app/src/util';
import { Units, Places, ItemDetails } from '../event-model';
import { COMMISSION_ACTION_TYPE } from '../commissionActionType';
import ScanProductPage from '../pages/ScanProductPage';

export const DATA_ROW_CLASS = 'event-data-row';
export const FIELD_WIDTH = '160px';

export const FormSpacer = () => <div style={{ height: '10px' }}/>;

const DatePicker = ({ value, onChange }) => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    textAlign: 'left',
    width: FIELD_WIDTH,
  };

  return (
    <input type="date" value={value} className={DATA_ROW_CLASS} style={style} onChange={onChange}/>
  );
};

const SelectOption = ({ label, value }) => <option key={label} value={value}>{label}</option>;

export const Select = ({ items, value, onChange, width = '200px', horizMargin = 'auto' }) => {
  const style = {
    display: 'block',
    border: `1px solid ${Colors.lightGrey}`,
    backgroundColor: 'white',
    height: '30px',
    minWidth: width,
    maxWidth: width,
    margin: `5px ${horizMargin}`,
    fontSize: '1rem',
  };

  return (
    <select style={style} className={DATA_ROW_CLASS} value={value} onChange={onChange}>
      {items.map(SelectOption)}
    </select>
  );
};

const MaterialSelect = ({ value, onChange }) => {
  const options = [
    { label: 'Select a material', value: '' },
    { label: 'Tape', value: 'tape' },
    { label: 'Ink', value: 'ink' },
  ];

  return (
    <Select width={FIELD_WIDTH} items={options} value={value}
      onChange={onChange} horizMargin="0px" />
  );
};

/* Dropdown from Order ID list */
const DestinationSelect = ({ value, onChange }) => {
  const initial = [
    { label: 'Select a destination...', value: '' },
  ];
  const options = initial.concat(Object.keys(Places).map(p => ({ label: p, value: p })));

  return (
    <Select width={FIELD_WIDTH} items={options} value={value} onChange={onChange}
      horizMargin="0px"/>
  );
};

const ItemDetailsSelect = ({ value, onChange }) => {
  const initial = [
    { label: 'Select item details...', value: '' },
  ];
  const options = initial.concat(ItemDetails.map(p => ({ label: p, value: p })));

  return (
    <Select width={FIELD_WIDTH} items={options} value={value} onChange={onChange}
      horizMargin="0px"/>
  );
};

const ScanProductButton = ({ setState }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: Colors.white,
    backgroundColor: Colors.black,
    height: '35px',
    width: '160px',
    fontFamily: Fonts.body,
    fontSize: '0.9rem',
    textAlign: 'center',
    cursor: 'pointer',
  };

  return (
    <div style={style} onClick={() => setState({ currentPage: ScanProductPage})}>
      Scan EAN13 Barcode
    </div>
  );
};

const ProductSelect = ({ state, setState, onChange }) => {
  const { description } = state.actionFields;
  const initial = [{ label: 'Select a product...', value: '' }];
  const productOptions = state.products
    // If description is selected, filter options
    .filter(p => !description ? true : p.description === description)
    .map(p => ({ label: `${p.name} (${p.identifiers.ean_13})`, value: p.id }));
  const options = initial.concat(productOptions);

  return (
    <div>
      <Select width={FIELD_WIDTH} items={options} value={state.actionFields.product}
        onChange={onChange} horizMargin="0px"/>
      <ScanProductButton setState={setState}/>
    </div>
  );
};

export const CollectionSelect = ({ state, identifier, value, onChange, horizMargin = 'auto', emptyLabel = 'Select an item' }) => {
  const initial = [{ label: `${emptyLabel}...`, value: '' }];
  const collectionOptions = state.collections
    .filter(p => p.identifiers[identifier])
    .map(p => ({ label: p.name.split(' ').slice(2), value: p.id }));
  const options = initial.concat(collectionOptions);

  return (
    <Select width={FIELD_WIDTH} items={options} value={value} horizMargin={horizMargin}
      onChange={onChange}/>
  );
};

const DataInput = ({ fieldName, value, onChange, test }) => {
  const style = {
    width: FIELD_WIDTH,
    textAlign: 'left',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    height: '30px',
    margin: '10px auto',
    paddingLeft: '5px',
    border: `1px solid ${Colors.lightGrey}`,
  };

  return (
    <input type="text" className={DATA_ROW_CLASS} style={style} value={value} 
      onChange={(el) => {
        if (test && !test(el.target.value)) {
          alert('Invalid value!');
          return;
        }

        onChange(el);
      }}/>
  );
};

const UnitsView = ({ fieldName }) => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '0.9rem',
    color: Colors.darkGrey,
    marginLeft: '5px',
  };

  return <span style={style}>{Units[fieldName]}</span>;
};

/**
 * Check a value is either empty, or a parsable number.
 *
 * @param {string} value - Value to test.
 * @returns {boolean} true if the value is empty or a number, false otherwise.
 */
const isEmptyOrNumber = value => value === '' || (value.length > 0 && parseInt(value));

/**
 * Special input field that only allows integers
 *
 * @param {object} props - Component props.
 * @returns {HTMLElement} The input element.
 */
const QuantityIntegerField = ({ state, setState, onChange, value }) => {
  return (
    <div>
      <DataInput fieldName="quantity" value={value} 
        test={isEmptyOrNumber} onChange={onChange}/>
      <UnitsView fieldName="quantity"/>
    </div>
  );
};

/**
 * Choose from a list of unique product descriptions.
 *
 * @param {object} props - Component props.
 * @returns {HTMLElement} - The input to select a description.
 */
const DescriptionSelect = ({ state, setState, onChange, value }) => {
  const initial = [{ label: 'Select a description...', value: '' }];
  const descOptions = state.products
    .map(p => p.description)
    .reduce((res, item) => res.includes(item) ? res : res.concat(item), [])
    .map(p => ({ label: p, value: p }));
  const options = initial.concat(descOptions);

  return (
    <Select width={FIELD_WIDTH} items={options} value={value} onChange={onChange} 
      horizMargin="0px"/>
  );
};

/**
 * Choose from a fixed number of order IDs.
 *
 * @param {object} props - Component props.
 * @returns {HTMLElement} The input to select order ID.
 */
const OrderIdSelect = ({ state, setState, onChange, value }) => {
  const orderIds = [ 'example ID' ];

  const initial = [{ label: 'Select an order ID...', value: '' }];
  const orderIdOptions = orderIds.map(p => ({ label: p, value: p }));
  const options = initial.concat(orderIdOptions);

  return (
    <Select width={FIELD_WIDTH} items={options} value={value}
      onChange={onChange} horizMargin="0px"/>
  );
}

/**
 * Build a Select for specifically filtered collections, if they exist (by identifier)
 * and provide an interface for updating those state values.
 *
 * @param {object} state - App state.
 * @param {string} identifier - Key in collection.identifiers used to filter and update state.
 * @param {string} inputStateKey - Key in app state where user input can be found.
 * @param {function} onChange - Updater function to run with the newly selected value.
 * @returns {CollectionSelect}
 */
const buildSelector = (state, identifier, inputStateKey, onChange) => {
  const chosenCollection = state.collections
      .find(p => p.identifiers[identifier] === state[inputStateKey]);
  const value = chosenCollection ? chosenCollection.id : '';
  return (
    <CollectionSelect state={state} identifier={identifier} horizMargin="0px" value={value}
      onChange={el => onChange(el.target.value, inputStateKey, identifier)}/>
  );
};

/**
 * Get the right input component for the field name.
 *
 * @param {object} state - App state.
 * @param {function} setState - App setState function.
 * @param {object} appCallbacks - App callbacks.
 * @param {string} fieldName - Name of the field.
 * @param {function} updateField - 'el' callback for element onchange.
 * @returns {HTMLElement} The element to use as input.
 */
export const getInputForm = (state, setState, appCallbacks, fieldName, updateField) => {
  const { onCollectionChosen } = appCallbacks;

  // rawMaterialLotNumber in _LabelsProduced is always selected
  if (fieldName === 'rawMaterialLotNumber') {
    return buildSelector(state, 'rawMaterialLotNumber', 'enteredRMLotNumber', onCollectionChosen);
  }

  // purchaseOrder is chosen from those available in commissions (Label Applied)
  if (fieldName === 'Customer PO' && state.actionType === COMMISSION_ACTION_TYPE) {
    return buildSelector(state, 'purchaseOrder', 'enteredPoId', onCollectionChosen);
  }

  // productionOrder is chosen from those available in commissions (Label Applied)
  if (fieldName === 'productionOrder' && state.actionType === COMMISSION_ACTION_TYPE) {
    return buildSelector(state, 'productionOrder', 'enteredPrOId', onCollectionChosen);
  }

  // Special type
  const map = {
    activationDate: DatePicker,
    productionDate: DatePicker,
    productionDates: DatePicker,
    receivingDate: DatePicker,
    shippingDate: DatePicker,
    date: DatePicker,
    materialType: MaterialSelect,
    destination: DestinationSelect,
    product: ProductSelect,
    quantity: QuantityIntegerField,
    description: DescriptionSelect,
    orderId: OrderIdSelect,
    itemDetails: ItemDetailsSelect,
  };
  if (map[fieldName]) {
    const Component = map[fieldName];
    return (
      <Component state={state} setState={setState}
        onChange={updateField}
        value={state.actionFields[fieldName] || ''}/>
    );
  }

  // Standard text field (including Customer PO Number initial entry)
  return (
    <div>
      <DataInput fieldName={fieldName} value={state.actionFields[fieldName] || ''} 
        onChange={updateField}/>
      <UnitsView fieldName={fieldName}/>
    </div>
  );
};
