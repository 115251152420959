import React from 'react';
import { whiteButtonStyle } from '../../../consumer-web-app/src/util';
import { FormSpacer, CollectionSelect } from '../components/FormComponents';
import Button from '../../../consumer-web-app/src/components/Button';
import Fader from '../../../consumer-web-app/src/components/Fader';
import DataEntryPage from './DataEntryPage';
import InputField from '../components/InputField';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

/** Production Order page, as opposed to Purchase Order page (PONumberPage) */
const PrONumberPage = ({ state, setState, setStateSync, appCallbacks }) => {
  const { onPrOIdConfirmed, onCollectionChosen } = appCallbacks;
  const { actionType, collection, collections } = state;

  const creatingResource = actionType === '_GarmentsProduced';
  const paragraph = creatingResource
    ? 'Enter the Production Order number to be used in creating the event.'
    : 'Choose an existing Production Order number to be used in creating the event.';

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/identify.png"/>
        <Title>Production Order Number Entry</Title>
        <Paragraph>{paragraph}</Paragraph>

        <FormSpacer/>
        {creatingResource === true && (
          <InputField type="text" placeholder="Production Order Number" value={state.enteredPrOId}
            onChange={el => setState({ enteredPrOId: el.target.value })}/>
        )}
        {creatingResource === false && (
          <CollectionSelect state={state} identifier="productionOrder" value={collection}
            onChange={(el) => {
              onCollectionChosen(el.target.value, 'enteredPrOId', 'productionOrder')
                .then(() => onPrOIdConfirmed());
            }}/>
        )}
        
        {creatingResource === true && <Button onClick={onPrOIdConfirmed}>Confirm</Button>}
        <Button restyle={whiteButtonStyle} onClick={() => setState({ currentPage: DataEntryPage })}>
          Back
        </Button>
      </WideContainer>
    </Fader>
  );
};

export default PrONumberPage;
