import { LS_USER_ID, LS_USER_KEY } from './util';
import { Events } from './event-model';
import DataEntryPage from './pages/DataEntryPage';
import LoginPage from './pages/LoginPage';

const ACTION_TYPE_CREATE_TASK = '_CreateTask';
const CHECK_INTERVAL_MS = 5000;

/**
 * Load the product options from the API
 *
 * @param {object} state - The app state.
 * @parma {Function} setState - The app setState function.
 * @returns {Promise} Promise that resolves when complete.
 */
export const loadProducts = (state, setState) => state.appUserScope.product()
  .read({ params: { perPage: 100 } })
  .then(products => setState({ products }));

/**
 * Load the collection options from the API
 *
 * @param {object} state - The app state.
 * @parma {Function} setState - The app setState function.
 * @returns {Promise} Promise that resolves when complete.
 */
export const loadCollections = (state, setState) => state.appUserScope.collection()
  .read()
  .then(collections => setState({ collections }));

/**
 * Load the place options from the API
 *
 * @param {object} state - The app state.
 * @parma {Function} setState - The app setState function.
 * @returns {Promise} Promise that resolves when complete.
 */
export const loadPlaces = (state, setState) => state.appUserScope.place()
  .read()
  .then(places => setState({ places }));

/**
 * Find a single Thng using an identifiers filter.
 *
 * @param {object} state - The app state.
 * @param {string} value - The identifiers value.
 * @returns {Promise} Promise that returns the Thng if found, else null.
 */
export const findThngByShortId = (state, value) => {
  const params = { filter: `identifiers.shortId=${value}` };
  const { appUserScope } = state;

  return appUserScope.thng().read({ params })
    .then((res) => {
      if (res.length && res[0].id) {
        const thng = res[0];

        // Patch the shortId to gs1:21 so commissions works
        const identifiers = Object.assign(
          thng.identifiers,
          { 'gs1:21': thng.identifiers.shortId }
        );
        return appUserScope.thng(thng.id).update({ identifiers });
      }

      return null;
    });
};

/**
 * Wait until all Thngs in missingSerials can be resolved by their shortId identifier
 *
 * @param {object} state - The app state.
 * @returns {Promise} Promise that resolves once all Thng IDs are known.
 */
export const waitForThngsCreated = state => new Promise((resolve) => {
  const handle = setInterval(() => {
    const promises = state.missingSerials.map(p => findThngByShortId(state, p));
    Promise.all(promises).then((res) => {
      if (!res.every(p => p.id || p.id.length !== 24)) {
        return;
      }

      clearInterval(handle);
      resolve(res);
    });
  }, CHECK_INTERVAL_MS);
});

/**
 * Create an action to request Thngs using Reactor script create-task.js
 *
 * @param {object} state - The app state.
 * @returns {Promise} Promise that resolves once the Thngs are created.
 */
export const requestThngsCreated = state => state.appUserScope
  .action(ACTION_TYPE_CREATE_TASK)
  .create({
    type: ACTION_TYPE_CREATE_TASK,
    customFields: { serials: state.missingSerials },
  })
  .then(() => waitForThngsCreated(state));

/**
 * Create the 'encodings' action on a Thng.
 *
 * @param {object} state - The app state.
 * @param {object} thng - The Thng to use.
 * @returns {Promise} A Promise that resolves once the action is created.
 */
export const createEncodingsAction = (state, thng) => state.appUserScope
  .thng(thng.id)
  .action('encodings')
  .create({ type: 'encodings', thng: thng.id });

/**
 * Add the commissioned Thng to a collection.
 *
 * @param {object} state - The app state.
 * @param {string} collectionId - The ID of the collection to use.
 * @param {object} thng - The Thng to use.
 * @returns {Promise} Promise that resolves when the process is complete.
 */
export const addThngToCollection = (state, collectionId, thng) => state.appUserScope
  .collection(collectionId)
  .thng()
  .update([thng.id]);

/**
 * Log the user out and remove stored credentials.
 *
 * @param {object} state - The app state.
 * @parma {Function} setState - The app setState function.
 */
export const logOut = (state, setState) => {
  localStorage.removeItem(LS_USER_ID);
  localStorage.removeItem(LS_USER_KEY);

  setState({ currentPage: LoginPage, appUserScope: null });

  if (state.appUserScope) {
    state.appUserScope.logout().catch(console.log);
  }
};

/**
 * Get or create the collection representing the entered batch or PO number.
 *
 * @param {object} state - The app state.
 * @param {string} inputValue - The value to use.
 * @param {string} filterKey - The identifiers filter key.
 * @param {string} namePrefix - Name prefix to use if collection is to be created.
 * @returns {string} The resulting collection ID.
 */
export const getCollectionId = (state, inputValue, filterKey, namePrefix) => {
  const params = { filter: `identifiers.${filterKey}=${inputValue}` };
  const { appUserScope } = state;

  return appUserScope.collection().read({ params })
    .then((collections) => {
      if (collections.length) {
        return collections[0].id;
      }

      const payload = {
        name: `${namePrefix} ${inputValue}`,
        identifiers: { [filterKey]: inputValue },
        customFields: { createdBy: appUserScope.email },
      };
      return appUserScope.collection().create(payload).then(res => res.id);
    });
};

/**
 * Get or create the collection representing the entered order number.
 * enteredRMLotNumber if entered, actionFields.rawMaterialLotNumber event field if a commissions event.
 *
 * @param {object} state - The app state.
 * @returns {string} The resulting collection Id
 */
export const getRMLotNumberCollection = (state) => {
  const value = state.enteredRMLotNumber || state.actionFields.rawMaterialLotNumber;
  return getCollectionId(state, value, 'rawMaterialLotNumber', 'RM Lot');
};

/**
 * Get or create the collection representing the entered PO number.
 * Whenever an event target is a PO collection, it's always the Customer PO.
 *
 * @param {object} state - The app state.
 * @returns {string} The resulting collection Id.
 */
export const getPOCollection = (state) => {
  const value = state.actionFields['Customer PO'] || state.enteredPoId;
  return getCollectionId(state, value, 'purchaseOrder', 'Purchase Order');
};

/**
 * Get or create the collection representing the entered production order number.
 *
 * @param {object} state - The app state.
 * @returns {string} The resulting collection Id.
 */
export const getPrOCollection = (state) => {
  const value = state.actionFields.productionOrder || state.enteredPrOId;
  return getCollectionId(state, value, 'productionOrder', 'Production Order');
};

/**
 * 'commissions' actions have a special format.
 *
 * @param {object} state - The app state.
 * @param {object} thng - The Thng to use.
 * @returns {Promise} A Promise that resolves once complete.
 */
export const commissionThng = (state, thng) => {
  const product = state.products.find(p => p.id === state.actionFields.product);
  const place = state.places.find(p => p.id === state.place);

  const payload = {
    product: `gs1:01:${product.identifiers['gs1:01']}`,
    occurredAt: new Date().toISOString(),
    location: { position: place.position },
    factoryMetadata: {
      factory: 'factory:alyx-factory-manufacturing',
    },
  };

  const thngIdentifier = `gs1:21:${thng.identifiers['gs1:21']}`;
  return EVT.api({
    url: `/thngs/${thngIdentifier}/actions/commissions`,
    authorization: state.appUserScope.apiKey,
    method: 'post',
    data: payload,
  });
};

/**
 * Determine if the event has already been created for a given Thng or collection.
 *
 * @param {object} state - The app state.
 * @param {Function} setState - The app setState function.
 * @param {string} id - The target object ID, a Thng or collection.
 * @param {string} targetType - The target type, either 'thng' or 'collection'.
 * @returns {Promise} Promise that resolves once the process is complete.
 */
export const checkEventExists = (state, setState, id, targetType) => {
  // Only unique items should avoid being repeated
  // Batch and PO collections for different raw materials share batch numbers.
  if (Events[state.actionType].idMethod !== 'scan') {
    return Promise.resolve();
  }

  return state.appUserScope[targetType](id)
    .action(state.actionType)
    .read()
    .then((res) => {
      if (res.length > 0) {
        throw new Error('This item has already been recorded with this event');
      }
    });
};

/* Determine if the resource already has an event in progress using a customField.
 * If it is, block it. If not, set the flag for reactor-iota to clear.
 *
 * @param {object} state - The app state.
 * @param {Function} setStateSync - The app setStateSync function.
 * @param {string} id - The target object ID, a Thng or collection.
 * @param {string} targetType - The target type, either 'thng' or 'collection'.
 * @returns {Promise} Promise that resolves once the process is complete.
 */
export const manageEventInProgress = (state, setStateSync, id, targetType) => {
  // Deactivated because IOTA script was sped up to a few seconds.
  return Promise.resolve()
    .then(() => setStateSync({ eventInProgress: true }));

  // Check the flag
  // return state.appUserScope[targetType](id).read().then((res) => {
  //   if (!res.customFields) {
  //     res.customFields = {};
  //   }

  //   // Block the event creation
  //   if (res.customFields.eventInProgress === true) {
  //     throw new Error('An event is already awaiting blockchain confirmation. Please try again in a few seconds.');
  //   }

  //   // Set the flag
  //   Object.assign(res.customFields, { eventInProgress: true });
  //   return state.appUserScope[targetType](id)
  //     .update({ customFields: res.customFields })
  //     .then(() => setStateSync({ eventInProgress: true }));
  // });
};

/**
 * Because the QR code identifying the Thng is not known for the earliest events,
 * When a label is applied, search through the collections with that 'Customer PO'
 * and add the Thng to the collection with that collection's rawMaterialLotNumber.
 * Big hack, but necessary.
 *
 * @param {object} state - The app state.
 * @param {object} thng - The Thng scanned.
 * @returns {Promise} Promise that resolves when complete.
 */
export const addThngToRMLotCollection = (state, thng) => {
  const { appUserScope, collections, enteredPoId } = state;

  // Find the collection with the specified PO number (state.enteredPoId via dropdown in form)
  const poCollection = collections.find(p => p.identifiers.purchaseOrder === enteredPoId);
  if (!poCollection) {
    throw new Error(`Could not find PO collection for this po: ${enteredPoId}!`);
  }

  // Find a _LabelsProduced action on the PO collection that includes the rawMaterialLotNumber.
  return appUserScope.collection(poCollection.id)
    .action('_LabelsProduced')
    .read()
    .then((res) => {
      if (!res.length) {
        throw new Error(`No _LabelsProduced action was found for collection ${poCollection.id}`);
      }

      // Find the collection with the found action's rawMaterialLotNumber customField
      const rmCollection = collections.filter(p => p.identifiers.rawMaterialLotNumber)
        .find(p => p.identifiers.rawMaterialLotNumber === res[0].customFields.rawMaterialLotNumber);
      if (!rmCollection) {
        throw new Error('Could not find a rawMaterialLotNumber collection to link with purchaseOrder!');
      }

      return rmCollection.id;
    })
    .then(id => appUserScope.collection(id).thng().update([thng.id]));
};
